<app-sidebar></app-sidebar>
<app-header></app-header>
<main>
    <div class="container-custom">
        <div class="columns">
            <div class="main overflow">

                <div class="title_container">
                    <div class="parent-flex buton_view">
                        <div class="title_left">
                            <div class="d-flex align-items-center">
                                <!-- <img src="assets/images/angle_left.png" alt=""> -->
                                <div class="title_lg">{{'Projects' | translate}}</div>
                                <!-- <div class="settingsBtn"><img src="assets/images/blueWheel.png" alt="" class="mr-2">Settings</div> -->
                            </div>
                            <div class="d-flex"></div>
                        </div>
                        <div class="endChild">
                            <button *ngIf="currentValue && (currentValue.permissions.createProject)"
                                class="btn_primary bg_blue" (click)="createProjectModal()">{{'New project' |
                                translate}}</button>
                            <div i class="sort_parent">
                                <button *ngIf="currentValue && (currentValue.permissions.createProject)"
                                    class="btn_primary bg_blue" (click)="addProjectTypeModal()">{{'Add Folder type' |
                                    translate}}</button>
                            </div>
                            <!-- <div class="sort_parent">
                                <mat-form-field class="custom-form-field1" appearance="none">
                                    <mat-select class="custom-select2" placeholder="{{'Filter' | translate}}" [(ngModel)]="selectedOption1">
                                        <mat-option value="2">{{'Time' | translate}}</mat-option>
                                        <mat-option value="green">{{'Date' | translate}}</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                            </div>
                            <div class="sort_parent ">
                                <mat-form-field class="custom-form-field1" appearance="none">
                                    <mat-select class="custom-select1" placeholder="{{'Sort by' | translate}}" [(ngModel)]="selectedOption">
                                        <mat-option value="2">{{'Time' | translate}}</mat-option>
                                        <mat-option value="green">{{'Date' | translate}}</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                            </div> -->
                            <!-- <img (click)="gridActive('listActive')" *ngIf="gridView" class="cursor_pointer" src="assets/images/threeLine.png" alt="">
                            <img *ngIf="gridView" class="cursor_pointer" src="assets/images/gridActive.png" alt="">
                            <img *ngIf="!gridView" class="cursor_pointer" src="assets/images/threeLineActive.png" alt="">
                            <img (click)="gridActive('gridActive')" *ngIf="!gridView" class="cursor_pointer" src="assets/images/grid.png" alt=""> -->

                        </div>
                    </div>
                    <div class="catalogueFunction mt-3">
                        <div class="toggle_screen">
                            <div class="one tabOption" (click)="clickTab('user')">
                                <h5 [ngClass]="{'color_blue': userSection == true}">{{'Projects' | translate}}</h5>
                                <div [ngClass]="{'lineExtent': userSection == true, 'line': userSection == false}">
                                </div>
                            </div>
                            <div class="two tabOption" (click)="clickTab('group')">
                                <h5 [ngClass]="{'color_blue': groupSection == true}">{{'Shared Files' | translate}}</h5>
                                <div [ngClass]="{'lineExtent': groupSection == true, 'line': groupSection == false}">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="projects" *ngIf="gridView" [hidden]="groupSection">
                    <div class="grid">
                        <div class="card1 p-15 cursor_pointer"
                            (click)="openProject(data.uuid,data.project_time_exceeded,data.checkout_status,data.checkout_access)"
                            *ngFor="let data of projectList index as i"
                            (contextmenu)="open($event, data); $event. preventDefault();">
                            <div>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="title_md d-flex">
                                        <div><img class="mr-12" src="assets/images/Union.png" alt=""></div>
                                        <div class="projectName-len">{{ data.name}}</div>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center mt-3 mb-3">
                                    <img class="" src="assets/images/elipse_grey.png" alt="">
                                    <div class="smText"> {{ data.file_count }} {{'Files' | translate}}, {{
                                        data.folder_count }} {{'Folders' | translate}}</div>
                                </div>

                            </div>
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="d-flex align-items-center" id="shared_user_id">
                                    <ul class="mb-0 ul_line mr-12" *ngIf="data.projectMembers?.length != 0">
                                        <li *ngFor="let img of data.projectMembers | slice:0:limit" class="d-inline">
                                            <img src="{{img.profile_pic}}" alt="" /></li>
                                    </ul>
                                    <img (click)="toShare(data, $event,data.project_time_exceeded,data.checkout_status,data.checkout_access)"
                                        class="cursor_pointer" src="assets/images/addUser.png" alt="" />
                                </div>
                                <h6 class="blue_letter">{{data.size}}</h6>
                            </div>
                            <!-- <div class="mt-3 project_exceeded" >
                                <p class="text-danger" *ngIf="data.project_time_exceeded == 1">*{{'Your project timeline has been exceeded' | translate}}</p>
                            </div> -->
                            <div class="mt-1 project_exceeded"
                                *ngIf="data.project_time_exceeded == 1 && data.checkout_status == 0">
                                <p class="text-danger"
                                    *ngIf="data.project_time_exceeded == 1 && data.checkout_status == 0">*{{'Your project timeline has been exceeded' | translate}}</p>
                            </div>
                            <div class="mt-1 project_exceeded"
                                *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded !== 1">
                                <p class="text-danger"
                                    *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded !== 1">
                                    *{{'The following project have been checked out' | translate}}</p>
                            </div>
                            <div class="mt-1 project_exceeded"
                                *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded == 1">
                                <p class="text-danger"
                                    *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded == 1">
                                    *{{'Your project timeline has been exceeded and checked out' | translate}}</p>
                            </div>
                            <div class="mt-1 project_exceeded"
                                *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded == 1">
                                <p class="text-danger"
                                    *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded == 1">
                                    *{{'Your project timeline has been exceeded' | translate}}</p>
                            </div>
                            
                            <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded !== 1">
                                <p class="text-danger">*{{'This file is checked out by . Return date: ' | translate}}</p>
                              </div>
                            <div>
                                <img class="mr-12 dots" src="assets/images/more_sm2.png" alt=""
                                    (click)="open($event, data); $event. preventDefault(); $event.stopPropagation();">
                            </div>
                        </div>
                    </div>
                    <div *ngIf="projectList && projectList.length == 0" class="text-center mt-5">
                        <img src="assets/images/noprojects.jpg" class="img-fluid mb-2">
                        <p>{{'No Projects Found' | translate}}</p>
                    </div>
                </div>

                <div [hidden]="userSection">
                    <div class="mb-5" *ngIf="folderList && folderList.length != 0">
                        <div class="sub_title_container">
                            <div class="title_md d-flex align-items-center"><img src="assets/images/folder_grey.png"
                                    alt="" class="title_sm_logo">{{'Folders' | translate}}</div>
                        </div>
                        <div class="folder-card">
                            <!-- (click)="openFolder(data.uuid)" -->
                            <div class="card1" (click)="openFolder(data.uuid,data.project_time_exceeded,data,data.checkout_status,data.checkout_access)"
                                *ngFor="let data of folderList index as i"
                                (contextmenu)="opencontext($event, data); $event. preventDefault();">
                                <label class="checkbox-control" *ngIf="showCheckBox">
                                    <input type="checkbox" [(ngModel)]="data.selected"
                                        (click)="$event.stopPropagation()" (change)="bulkDelete($event, data.uuid)"
                                        class="inputRadiobutton2 checkbox" *ngIf="showCheckBox"><span
                                        class="checkbox-control__target">Card Label</span></label>
                                <div class="d-flex align-items-center">
                                    <img class="mr-12" src="assets/images/folder.png" alt="">
                                    <div>
                                        <div class="subText">{{ data.folder_name }}</div>
                                        <div class="smText">{{data.file_count}} {{'Files' | translate}}, {{
                                            data.folder_count }} {{'Folders' | translate}}</div>
                                    </div>

                                </div>
                                <div class="mt-1 project_exceeded"
                                    *ngIf="data.project_time_exceeded == 1 && data.checkout_status == 0">
                                    <p class="text-danger"
                                        *ngIf="data.project_time_exceeded == 1 && data.checkout_status == 0">*{{'The timeline of following files have expired' | translate}}</p>
                                </div>
                                <div class="mt-1 project_exceeded"
                                    *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded !== 1">
                                    <p class="text-danger"
                                        *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded !== 1">
                                        *{{'This file is checked out by . Return date: ' | translate}}</p>
                                </div>
                                <div class="mt-1 project_exceeded"
                                    *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded == 1">
                                    <p class="text-danger"
                                        *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded == 1">
                                        *{{'The timeline of following folder have expired and checked out' | translate}}
                                    </p>
                                </div>
                                <div class="mt-1 project_exceeded"
                                    *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded == 1">
                                    <p class="text-danger"
                                        *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded == 1">
                                        *{{'The timeline of following folder have expired and checked out' | translate}}
                                    </p>
                                </div>
                                <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded !== 1">
                                    <p class="text-danger">*{{'You have checked out this folder. Please return before' | translate}}</p>
                                  </div>
                            </div>

                        </div>
                    </div>

                    <div class="mb-5 mt-5" *ngIf="fileList && fileList.length != 0">
                        <div class="sub_title_container">
                            <div class="title_md d-flex align-items-center"><img src="assets/images/document_grey.png"
                                    alt="" class="title_sm_logo">{{'Files' | translate}}</div>
                            <div class="filters"></div>
                        </div>

                        <div class="files-card mt-4">
                            <div class="files" type="radio" *ngFor="let data of fileList index as i"
                                (contextmenu)="opencontext($event, data); $event. preventDefault();"
                                (click)="getImagePreview(data)">
                                <label class="checkbox-control checkboxBootstrap" *ngIf="showCheckBox">
                                    <input type="checkbox" [(ngModel)]="data.selected"
                                        (click)="$event.stopPropagation()" (change)="bulkDelete($event, data.uuid)"
                                        class="inputRadiobutton2 checkbox" *ngIf="showCheckBox"><span
                                        class="checkbox-control__target">Card Label</span></label>
                                <div class="smText d-flex align-items-center">
                                    <img src="{{data.preview_image}}" class="img-fluid" alt="">
                                    <span class="textFilename">{{ data.file_name }}</span>
                                </div>
                                <div class="previewDiv"><img class="img-fluid w-100" src="{{data.preview_url}}"
                                        *ngIf="data.preview_url" alt=""></div>
                                <div><img class="img-fluid w-100" src="assets/images/image_preview.png" alt=""
                                        *ngIf="!data.preview_url"></div>
                                <!-- <div class="smText d-flex align-items-center"><img src="assets/images/timer.png"  alt="">You opened this on yesterday</div> -->
                                <div class="mt-1 project_exceeded file_exceeded"
                                    *ngIf="data.checkout_status == 1 && data.checkout_access !== 1">
                                    <p class="text-danger"
                                        *ngIf="data.checkout_status == 1 && data.checkout_access !== 1">*{{'The following file have been checked out' | translate}}</p>
                                </div>
                                <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded !== 1">
                                    <p class="text-danger">*{{'You have checked out this folder. Please return before' | translate}}</p>
                                  </div>
                            </div>

                        </div>
                        <div *ngIf="fileList &&  fileList.length == 0" class="text-center">
                            <img src="assets/images/doc.png" class="img-fluid mb-2">
                            <p>{{'No Files Found' | translate}}</p>
                        </div>
                    </div>
                    <div *ngIf="(fileList && folderList && (folderList.length == 0 && fileList.length == 0))"
                        class="text-center mt-5">
                        <img src="assets/images/noprojects.jpg" class="img-fluid mb-2">
                        <p>{{'No Shared files Found' | translate}}</p>
                    </div>
                </div>


                <ng-template #userMenu let-user>
                    <section class="user-menu"
                        [hidden]="(user.project_time_exceeded == '1') && (!currentValue.permissions.archiveDelete)">
                        <div (click)="toShare()"
                            *ngIf="(user.project_time_exceeded == 0) && (currentValue && (currentValue.permissions.ShareFile))">
                            <img _ngcontent-ofp-c1="" alt="" src="assets/images/share_oprtion.png"> {{'Share' |
                            translate}}</div>
                        <div *ngIf="user.project_time_exceeded == 0" (click)="getLink()"><img _ngcontent-ofp-c1=""
                                alt="" src="assets/images/url_oprtion.png"> {{'Get Link' | translate}}</div>
                        <div *ngIf="user.project_time_exceeded == 0" (click)="rename()"><img _ngcontent-ofp-c1="" alt=""
                                src="assets/images/edit_oprtion.png"> {{'Rename' | translate}}</div>
                        <div *ngIf="user.project_time_exceeded == 0" (click)="copyProject()"><img _ngcontent-ofp-c1=""
                                alt="" src="assets/images/copy_oprtion.png"> {{'Copy' | translate}}</div>
                        <div (click)="deleteModal()"
                            *ngIf="(user.project_time_exceeded == 0) && (currentValue && (currentValue.permissions.deleteProject))">
                            <img _ngcontent-ofp-c1="" alt="" src="assets/images/delete_oprtion.png"> {{'Delete' |
                            translate}}</div>
                        <div (click)="openArchieveModal()"
                            *ngIf="currentValue &&(currentValue.permissions.archiveDelete)"><img _ngcontent-ofp-c1=""
                                alt="" src="assets/images/recycle.png">{{'Add to archive' | translate}}</div>
                        <div *ngIf="(user.project_time_exceeded == 0 && (user.checkout_status !== 1 && user.checkout_access !== 1))" (click)="checkoutModal()"><img
                                _ngcontent-ofp-c1="" alt="" src="assets/images/checkout.png"> {{'Check out' |
                            translate}}</div>
                        <div *ngIf="(user.project_time_exceeded == 0 && (user.checkout_status == 1 && user.checkout_access == 1))" (click)="checkoutModal('2')"><img
                                _ngcontent-ofp-c1="" alt="" src="assets/images/checkout.png"> {{'Check in' |
                            translate}}</div>
                    </section>
                </ng-template>

                <ng-template #fileFolderMenu let-user>
                    <section class="user-menu"
                        [hidden]="(user.project_time_exceeded == '1') && (!currentValue.permissions.archiveDelete)">
                        <div (click)="toShare()" *ngIf="(user.project_time_exceeded == 0)"><img _ngcontent-ofp-c1=""
                                alt="" src="assets/images/share_oprtion.png"> {{'Share' | translate}}</div>
                        <div (click)="rename()" *ngIf="(user.project_time_exceeded == 0)"><img _ngcontent-ofp-c1=""
                                alt="" src="assets/images/edit_oprtion.png"> {{'Rename' | translate}}</div>
                        <div (click)="deleteModal()" *ngIf="delete && (user.project_time_exceeded == 0)"><img
                                _ngcontent-ofp-c1="" alt="" src="assets/images/delete_oprtion.png"> {{'Delete' |
                            translate}}</div>
                        <div (click)="openArchieveModal()"
                            *ngIf="(currentValue &&(currentValue.permissions.archiveDelete)) && (user.project_time_exceeded == 0)">
                            <img _ngcontent-ofp-c1="" alt="" src="assets/images/recycle.png">{{'Add to archive' |
                            translate}}</div>
                        <div *ngIf="(user.project_time_exceeded == 0 && (user.checkout_status !== 1 && user.checkout_access !== 1))" (click)="checkoutModal()"><img
                            _ngcontent-ofp-c1="" alt="" src="assets/images/checkout.png"> {{'Check out' |
                        translate}}</div>
                    <div *ngIf="(user.project_time_exceeded == 0 && (user.checkout_status == 1 && user.checkout_access == 1))" (click)="checkoutModal('2')"><img
                            _ngcontent-ofp-c1="" alt="" src="assets/images/checkout.png"> {{'Check in' |
                        translate}}</div>
                    </section>
                </ng-template>

                <div class="modal fade" id="deleteModal3" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
                    tabindex="-1">
                    <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal_header">
                                <h5>{{'Delete' | translate}} ?</h5>
                                <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="text-center">{{'Do you want to delete' | translate}}</div>
                            </div>
                            <div id="delete_id" class="btnGrp mt-4">
                                <button id="button-id" class="btn_primary bg_blue" #closebutton data-bs-dismiss="modal"
                                    aria-label="Close">{{'Cancel' | translate}}</button>
                                <button id="button-id" class="btn_primary bg_red" (click)="deleteProject()">{{'Delete' |
                                    translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="deleteModalforArchieve_project" aria-hidden="true"
                    aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                    <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal_header">
                                <h5>{{'Archive' | translate}} ?</h5>
                                <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="text-center">{{'Do you want to archive ?' | translate}} </div>
                            </div>
                            <div id="delete_id" class="btnGrp mt-4">
                                <button id="button-id" class="btn_primary bg_blue" #closebutton data-bs-dismiss="modal"
                                    aria-label="Close">{{'Cancel' | translate}}</button>
                                <button id="button-id" class="btn_primary bg_red" (click)="restoreLater()">{{'Archive' |
                                    translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="deleteModalforArchieve_open" aria-hidden="true"
                    aria-labelledby="exampleModalToggleLabel" tabindex="-1"
                    *ngIf="(currentValue &&(currentValue.permissions.editProjectSettings)) || (currentValue &&(currentValue.permissions.archiveDelete))">
                    <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal_header">
                                <h5>{{'Archive' | translate}} ?</h5>
                                <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div class="text-center text-danger">*{{'Your project timeline has been exceeded' |
                                    translate}}.{{'Do you want archive' | translate}} ? </div>
                            </div>
                            <div id="delete_id" class="btnGrp mt-4">
                                <button id="button-id" class="btn_primary bg_blue"
                                    *ngIf="currentValue &&(currentValue.permissions.editProjectSettings)"
                                    (click)="updateTimeline()">{{'Update Timeline' | translate}}</button>
                                <button id="button-id" class="btn_primary bg_red"
                                    *ngIf="currentValue &&(currentValue.permissions.archiveDelete)"
                                    (click)="openArchieveModal()">{{'Archive' | translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="project_table" *ngIf="!gridView">
                    <div class="wrapper" id="tableview">
                        <div class="Rtable Rtable--5cols Rtable--collapse" id="table-main">

                            <div class="Rtable-row is-striped cursor_pointer"
                                *ngFor="let data of projectList index as i"
                                (contextmenu)="open($event, data); $event. preventDefault();"
                                (click)="openProject(data.uuid,data.project_time_exceeded,data.checkout_status,data.checkout_access)">
                                <div class="Rtable-cell name_cell">
                                    <div class="Rtable-cell--content title-content"><img class="mr-12"
                                            src="assets/images/Union.png" alt=""><span>{{ data.name }}</span></div>
                                </div>
                                <div class="Rtable-cell size_cell">
                                    <div class="Rtable-cell--heading">File Size</div>
                                    <div class="Rtable-cell--content replay-link-content">{{ data.file_count }}
                                        {{'Files' | translate}}, {{ data.folder_count }} {{'Folders' | translate}}</div>
                                </div>
                                <div class="Rtable-cell date-cell">
                                    <div class="Rtable-cell--heading">{{'Last Modified' | translate}}</div>
                                    <div class="Rtable-cell--content date-content"><span class="">{{data.size}}</span>
                                    </div>
                                </div>

                                <div class="Rtable-cell Rtable-cell--foot shared-cell">
                                    <div class="Rtable-cell--heading">{{'Shared with' | translate}}</div>
                                    <div class="Rtable-cell--content shared-content">
                                        <div class="d-flex align-items-center" id="shared_user_id">
                                            <ul class="p-0">
                                                <li *ngFor="let img of data.projectMembers" class="d-inline"><img
                                                        src="{{img.profile_pic}}" alt="" /></li>
                                            </ul>
                                            <img (click)="toShare(data.uuid)" class="cursor_pointer"
                                                src="assets/images/addUser.png" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="Rtable-cell location_cell">
                                    <div class="Rtable-cell--heading">{{'Location' | translate}}</div>
                                    <div class="Rtable-cell--content access-link-content"><img _ngcontent-oee-c1=""
                                            alt="" class="mr-12" src="assets/images/more_sm2.png"
                                            (click)="open($event, data); $event. preventDefault();"></div>
                                </div>
                                <!-- <div id="context-menu1" class="context-menu">
                                    <ul class="context-menu__items">
                                    <li class="context-menu__item">
                                        <a href="#" class="context-menu__link" data-action="View"><img src="assets/images/share_oprtion.png" alt=""> Share</a>
                                    </li>
                                    <li class="context-menu__item">
                                        <a href="#" class="context-menu__link" data-action="Edit"><img src="assets/images/url_oprtion.png" alt=""> Get Link</a>
                                    </li>
                                    <li class="context-menu__item">
                                        <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/download_oprtion.png" alt=""> Download</a>
                                    </li>
            
                                    <li class="context-menu__item">
                                        <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/edit_oprtion.png" alt=""> Rename</a>
                                    </li>
                                    <li class="context-menu__item">
                                        <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/copy_oprtion.png" alt=""> Copy</a>
                                    </li>
                                    <li class="context-menu__item">
                                        <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/delete_oprtion.png" alt=""> Delete</a>
                                    </li>
                                    <li class="context-menu__item">
                                        <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/starOption.png" alt=""> Favorite</a>
                                    </li>
                                    <li class="context-menu__item">
                                        <a href="#" class="context-menu__link" data-action="Delete"><img src="assets/images/eye_oprtion.png" alt=""> View Details</a>
                                    </li>
                                    </ul>
                                </div> -->

                            </div>

                        </div>
                    </div>
                    <div *ngIf="projectList && projectList.length == 0" class="text-center mt-5">
                        <img src="assets/images/noprojects.jpg" class="img-fluid mb-2">
                        <p>{{'No Projects Found' | translate}}</p>
                    </div>
                </div>
                <div class="modal fade" id="createProjectModal" aria-hidden="true" data-bs-keyboard="false"
                    data-bs-backdrop="static" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalToggleLabel">{{'New project' | translate}}</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    (click)="closeModal()"></button>
                            </div>
                            <form [formGroup]="addProjectForm" (ngSubmit)="onSubmitnewProject()">
                                <div class="modal-body">
                                    <div class="container_input_flex">
                                        <div class="input_style">
                                            <label for="exampleInputEmail1">{{'Project Title' | translate}}</label>
                                            <input class="input" type="text" aria-describedby="emailHelp"
                                                placeholder="{{'Full Name' | translate}}" formControlName="project_name"
                                                maxlength="32">
                                            <div *ngIf="submitted && b.project_name.errors" class="m-2">
                                                <div class="error_message" *ngIf="b.project_name.errors.required">
                                                    {{'Project Title' | translate}} {{'is required' | translate}}</div>
                                                <div class="error_message" *ngIf="b.project_name.errors.pattern">
                                                    {{'Project Title' | translate}} {{'shoud valid' | translate}}</div>
                                            </div>
                                        </div>
                                        <!-- <div class="sort_parent input_style">
                                            <label for="exampleInputEmail1">{{'Project type' | translate }}</label>
                                            <mat-form-field class="custom-form-field2" appearance="none">
                                                <mat-select [(ngModel)]="selectedOption"
                                                    placeholder="{{'Select Project type' | translate}}"
                                                    formControlName="project_type">
                                                    <mat-option value="{{types.id}}"
                                                        *ngFor="let types of ProjectTypeslist"><span
                                                            class="option-text">{{ types.name}}</span>
                                                        <mat-icon *ngIf="!(types.key)"
                                                            class="delete-icon  deleteIconImage"
                                                            (click)="deleteProjectType(types.id)"><i
                                                                class="fa-solid fa-trash"></i></mat-icon>
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <div *ngIf="submitted && b.project_type.errors" class="m-2">
                                                <div class="error_message" *ngIf="b.project_type.errors.required">
                                                    {{'Project type' | translate}} {{'is required' | translate}}</div>
                                                <div class="error_message" *ngIf="b.project_type.errors.pattern">
                                                    {{'Project type' | translate}} {{'shoud valid' | translate}}</div>
                                            </div>
                                        </div> -->
                                    </div>

                                    <div class="input_style mt-4">
                                        <label for="exampleInputEmail1">{{'Project Description' | translate}}</label>
                                        <textarea name="" class="" id="" cols="30" rows="5"
                                            placeholder="{{'Project  Description' | translate}}"
                                            formControlName="project_description"></textarea>
                                        <div *ngIf="submitted && b.project_description.errors" class="m-2">
                                            <div class="error_message" *ngIf="b.project_description.errors.required">
                                                {{'Project Description' | translate}} {{'is required' | translate}}
                                            </div>
                                            <div class="error_message" *ngIf="b.project_description.errors.pattern">
                                                {{'Project Description' | translate}} {{'shoud valid' | translate}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="input_style mt-4">
                                        <label for="exampleInputEmail1">{{'Priority' | translate}}</label>

                                        <div class="select-createGroup">
                                            <mat-form-field class="custom-form-field2" appearance="none">
                                                <mat-select placeholder="{{'Select Priority' | translate}}"
                                                    formControlName="tag">
                                                    <mat-option value="1">{{ 'High'| translate }}</mat-option>
                                                    <mat-option value="2">{{'Medium' | translate}}</mat-option>
                                                    <mat-option value="3">{{'Low' | translate}}</mat-option>
                                                    <mat-option value="{{item.id}}"
                                                        *ngFor="let item of priority">{{item.name}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <div *ngIf="submitted && b.tag.errors" class="m-2">
                                                <div class="error_message" *ngIf="b.tag.errors.required">{{'Priority' |
                                                    translate}} {{'is required' | translate}}</div>
                                                <!-- <div class="error_message" *ngIf="b.project_type.errors.pattern">{{'Project type' | translate}} {{'shoud valid' | translate}}</div> -->
                                            </div>
                                        </div>

                                        <!-- <div class="tagArea">
                                        <input type="text" id="tag-input1">
                                    </div>
                                    <div class="error_message" *ngIf="folderSubmitted && tagInput1.arr.length === 0">
                                        {{ 'Project Tags is required' | translate }}
                                    </div> -->
                                    </div>
                                    <div class="container_input_flex">
                                        <div class="input_style mt-4 d-flex align-items-center">
                                            <div class="datePicker">
                                                <div>
                                                    <label for="">{{'Timeline Starts from' | translate}}</label>
                                                    <mat-form-field class="example-full-width" appearance="none">
                                                        <input matInput [matDatepicker]="dp10" [min]="todayDate"
                                                            [matDatepicker]="picker" readonly
                                                            formControlName="project_start_date"
                                                            (dateChange)="updateEndDate()">
                                                        <mat-datepicker-toggle matIconSuffix
                                                            [for]="dp10"></mat-datepicker-toggle>
                                                        <mat-datepicker #dp10></mat-datepicker>
                                                    </mat-form-field>
                                                    <div *ngIf="submitted && b.project_start_date.errors" class="m-2">
                                                        <div class="error_message"
                                                            *ngIf="b.project_start_date.errors.required">{{'Project
                                                            start date' | translate}} {{'is required' | translate}}
                                                        </div>
                                                        <div class="error_message"
                                                            *ngIf="b.project_start_date.errors.matDatepickerMin">
                                                            {{'Project start date' | translate}} {{'should valid' |
                                                            translate}}</div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <img class="minus" src="assets/images/minus.png" alt="">
                                                </div>
                                                <div>
                                                    <div>
                                                        <label for="">{{'Timeline Ends on' | translate}}</label>
                                                        <mat-form-field class="example-full-width" appearance="none">
                                                            <input matInput [matDatepicker]="dp2"
                                                                [matDatepicker]="picker" readonly
                                                                formControlName="project_end_date"
                                                                [min]="b.project_start_date.value">
                                                            <mat-datepicker-toggle matIconSuffix
                                                                [for]="dp2"></mat-datepicker-toggle>
                                                            <mat-datepicker #dp2></mat-datepicker>
                                                        </mat-form-field>
                                                        <div *ngIf="submitted && b.project_end_date.errors" class="m-2">
                                                            <div class="error_message"
                                                                *ngIf="b.project_end_date.errors.required">{{'Project
                                                                end date' | translate}} {{'is required' | translate}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="container_input_flex mt-4">
                                        <div class="input_style">
                                            <label for="exampleInputEmail1">{{'Project Code' | translate}}</label>
                                            <input class="input" type="tel" aria-describedby="emailHelp"
                                                placeholder="{{'Project Code' | translate}}"
                                                formControlName="project_code" maxlength="16">
                                            <div *ngIf="submitted && b.project_code.errors" class="m-2">
                                                <div class="error_message" *ngIf="b.project_code.errors.required">
                                                    {{'Project Code' | translate}} {{'is required' | translate}}</div>
                                                <div class="error_message" *ngIf="b.project_code.errors.pattern">
                                                    {{'Project Code' | translate}} {{'shoud valid' | translate}}</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="title mt-4"
                                        *ngIf="(userlist && userlist.length > 0) || (grouplist && grouplist.length > 0)">
                                        {{'Project Members' | translate}}</div>
                                    <div class="membersList"
                                        *ngIf="(userlist && userlist.length > 0) || (grouplist && grouplist.length > 0)">
                                        <div class="members_container">
                                            <div class="members" *ngFor="let item of projectMembers index as i">
                                                <div *ngIf="item.image" class="project_User_add"> <img
                                                        class="membersPic" src="{{item.image}}" alt="">
                                                    <p>{{item.name}}</p>
                                                </div>
                                                <div *ngIf="!item.image" class="d-flex">
                                                    <div class="groupIconMembersList"
                                                        [style.background-color]="colors[i]">{{getShortName(item.icon)}}
                                                    </div>
                                                    <div class="groupNameMembersList">{{item.name}}</div>
                                                </div>

                                                <img class="removeUserandGroup" (click)="removeFromMembersList(item)"
                                                    src="assets/images/close_sm.png" alt="">
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div *ngIf="submitted && projectMembers.length == 0" class="m-2">
                                    <div class="error_message">{{'Project Members' | translate  }} {{'is required' | translate}}</div>
                                </div> -->
                                    <div class="title mt-4" *ngIf="(grouplist && grouplist.length > 0)">{{'Add Groups' |
                                        translate}}</div>
                                    <div class="addUserList" *ngIf="(grouplist && grouplist.length > 0)">
                                        <div class="groups">
                                            <div class="list" *ngFor="let item of grouplist index as i">
                                                <div class="logoArea">
                                                    <!-- <img class="" src="assets/images/group1.png" alt=""> -->
                                                    <div class="s-name mr-12" [style.background-color]="colors[i]">
                                                        {{getShortName(item.group_name)}}
                                                    </div>
                                                    <div class="groupName">{{ item.group_name}} <span>{{ item.members}}
                                                            {{'Members' | translate}}</span></div>
                                                </div>
                                                <button type="button" class="btn_primary bg_blue"
                                                    href="#exampleModalToggle"
                                                    (click)="addGroupMembersList(item)">{{'Add' | translate}}</button>
                                            </div>
                                            <!-- <div class="list">
                                            <div class="logoArea">
                                                <img class="" src="assets/images/group2.png" alt="">
                                                <div>Design Group <span>5 members</span></div>
                                            </div>
                                            <button class="btn_primary bg_blue" href="#exampleModalToggle">Add</button>
                                        </div>
                                        <div class="list">
                                            <div class="logoArea">
                                                <img class="" src="assets/images/group2.png" alt="">
                                                <div>Design Group <span>5 members</span></div>
                                            </div>
                                            <button class="btn_primary bg_blue" href="#exampleModalToggle">Add</button>
                                        </div>
                                        <div class="list">
                                            <div class="logoArea">
                                                <img class="" src="assets/images/group1.png" alt="">
                                                <div>Design Group <span>5 members</span></div>
                                            </div>
                                            <button class="btn_primary bg_blue" href="#exampleModalToggle">Add</button>
                                        </div> -->
                                        </div>
                                    </div>



                                    <div class="title mt-4" *ngIf="(userlist && userlist.length > 0)">{{'Add Users' |
                                        translate}}</div>
                                    <div class="addUserList" *ngIf="(userlist && userlist.length > 0)">
                                        <div class="groups">
                                            <div class="list" *ngFor="let item of userlist">
                                                <div class="logoArea">
                                                    <img class="propicUser" src="{{item.profile_pic}}" alt="">
                                                    <div>{{ item.name }} <span>{{ item.email }}</span></div>
                                                </div>
                                                <button class="btn_primary bg_blue" type="button"
                                                    (click)="addUsersToList(item)">{{'Add' | translate}}</button>
                                            </div>
                                            <!-- <div class="list">
                                            <div class="logoArea">
                                                <img class="" src="assets/images/dev2.png" alt="">
                                                <div>James bellward <span>jamesbell@gmail.com</span></div>
                                            </div>
                                            <button class="btn_primary bg_blue" href="#exampleModalToggle">Add</button>
                                        </div>
                                        <div class="list">
                                            <div class="logoArea">
                                                <img class="" src="assets/images/dev3.png" alt="">
                                                <div>Casper kay <span>casperkay@gmail.com</span></div>
                                            </div>
                                            <button class="btn_primary bg_blue" href="#exampleModalToggle">Add</button>
                                        </div>
                                        <div class="list">
                                            <div class="logoArea">
                                                <img class="" src="assets/images/dev1.png" alt="">
                                                <div>Mars kony <span>marskony@gmail.com</span></div>
                                            </div>
                                            <button class="btn_primary bg_blue" href="#exampleModalToggle">Add</button>
                                        </div> -->
                                        </div>
                                    </div>

                                </div>
                                <div class="modal-footer">
                                    <button class="btn_primary bg_blue" type="submit">{{'Create project' |
                                        translate}}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="modal fade" id="addProjectTypeModal" aria-hidden="true" data-bs-keyboard="false"
                    data-bs-backdrop="static" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                    <div id="modal-id" class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal_header">
                                <h5>{{'Add Folder type' | translate}}</h5>
                                <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close" (click)="closseModal()"></button>
                            </div>
                            <form [formGroup]="addProjectTypeForm" (ngSubmit)="onSubmitnewDataType()">
                                <div class="modal-body">
                                    <div class="input_style">
                                        <label for="exampleInputEmail1">{{'Folder type' | translate}}</label>
                                        <input class="input" type="text" placeholder="{{'Folder type' | translate}}"
                                            formControlName="name" maxlength="32">
                                    </div>
                                    <div *ngIf="projectTypesubmitted && c.name.errors" class="m-2">

                                        <div class="error_message" *ngIf="c.name.errors.required">{{'Folder type' |
                                            translate}} {{'is required' | translate}}</div>
                                        <div class="error_message" *ngIf="c.name.errors.pattern">{{'Folder type' |
                                            translate}} {{'shoud valid' | translate}}</div>
                                    </div>
                                </div>
                                <button type="submit" class="btn_primary bg_blue d-block">{{'Add' | translate}}</button>
                            </form>
                        </div>
                    </div>
                </div>

            </div>

            <!-- <app-file-preview></app-file-preview> -->
            <app-file-preview></app-file-preview>
            <!-- <aside class="sidebar-second" id="preview_info" [ngClass]="{'width-side-second': showSideBar == true}">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="heading_1 w-100">
                        <h4 class="text-center">{{'File preview' | translate}}</h4>
                    </div>
                    <img class="close_storage" *ngIf="showSideBar" (click)="getInfo('1')" src="assets/images/arrow_right.png" alt="">
                    <img *ngIf="!showSideBar" class="close_storage2" (click)="getInfo('2')" src="assets/images/angle_left.png" alt="">
                </div>
                <div class="image_preview">
                    <img src="assets/images/pic_1.png" alt="" class="img-fluid">
                </div>

                <div class="size-col">
                    <div class="">
                        <img src="assets/images/image.png" alt="">
                        <span>illustration.ai</span>
                    </div>

                    <h5>1.2 MB</h5>
                </div>

                <div class="tabs">
                    <div class="position-relative tabOption cursor_pointer" data-tab="tab-1" id="infoShow" (click)="show('info')">
                        <h6>{{'Info' | translate}}</h6>
                        <div class="line" [ngClass]="{'lineExtent': infoSection == true}"></div>
                    </div>
                    <div class="position-relative tabOption cursor_pointer" data-tab="tab-2" id="activityShow" (click)="show('activity')">
                        <h6>{{'Activity' | translate}}</h6>
                        <div class="line" [ngClass]="{'lineExtent': activitySection == true}"></div>
                    </div>
                </div>


                <div class="tabs_contents">
                    <div class="info_content tab-content" *ngIf="infoSection">
                        <div class="text_sm">{{'Who can access' | translate}}</div>
                        <div class="d-flex justify-content-between info-1 margin-lr14">
                            <div class="d-flex justify-content-between align-items-center w-100 share-option">
                                <button class="share" data-toggle="modal" data-bs-toggle="modal" data-bs-target="#shareModal">
                                    <img src="assets/images/upload-sm.png" alt="">
                                    {{'Share' | translate}}
                                </button>
                                <div class="users">
                                    <ul>
                                        <li><img src="assets/images/employees1.png" alt=""></li>
                                        <li><img src="assets/images/employees2.png" alt=""></li>
                                        <li><img src="assets/images/employees3.png" alt=""></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="text_sm">{{'File properties' | translate}}</div>

                        <div class="margin-lr14 info-2">
                            
                            <div class="row">
                                <div class="col-md-6"> <h5 class="black">{{'Saved location' | translate}}</h5></div>
                                <div class="col-md-6"><h5>My files/illustrations</h5></div>
                                <div class="col-md-6"><h5 class="black">{{'Size' | translate}}</h5></div>
                                <div class="col-md-6"><h5>1.2 MB</h5></div>
                                <div class="col-md-6"><h5 class="black">{{'Modified' | translate}}</h5></div>
                                <div class="col-md-6"><h5>January 04, 2023</h5></div>
                                <div class="col-md-6"><h5 class="black">{{'Type' | translate}}</h5></div>
                                <div class="col-md-6"><h5>Image</h5></div>

                            </div>
                        </div>
                    </div>

                    <div class="activity_content tab-content" *ngIf="activitySection">
                        <div class="d-flex align-items-center margin-lr14 shared_1">
                            <img src="assets/images/pic-lg.png" alt="">
                            <div class="user_text">
                                {{'You shared this file' | translate}}
                                <h6>Jan 21th 2023, 03:14 pm</h6>
                            </div>
                        </div>
                        <div class="d-flex shared_2">
                            <img src="assets/images/link.png" alt="">
                            {{'Anyone with the link can view this file' | translate}}
                        </div>
                        <div class="line"></div>
                        <div class="d-flex align-items-center margin-lr14 shared_1">
                            <img src="assets/images/pic-lg.png" alt="">
                            <div class="user_text">
                                {{'You added to favourites' | translate}}
                                <h6>Jan 21th 2023, 03:14 pm</h6>
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="d-flex align-items-center margin-lr14 shared_1">
                            <img src="assets/images/pic-lg.png" alt="">
                            <div class="user_text">
                                {{'You uploaded' | translate}} <span>illustration.ai</span>
                                <h6>Jan 17th 2023, 10:47 am</h6>
                            </div>
                        </div>
                        <div class="line"></div>
                    </div>


                </div>
            </aside> -->



        </div>
    </div>
</main>
<app-move-modal></app-move-modal>
<div class="modal fade" id="folderEdit_timlinemodal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="shareModalLabel" aria-hidden="true">
    <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal_header">
                <h5>{{'Folder details' | translate}}</h5>
                <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <form [formGroup]="timelineForm">
                <div class="modal-body">

                    <div class="input_style mt-4">

                        <label for="exampleInputEmail1">{{'Folder Name' | translate}}</label>
                        <input class="input" type="email" aria-describedby="emailHelp"
                            placeholder="{{'Folder Name' | translate}}" readonly formControlName="name">
                    </div>

                    <div class="container_input_div">
                        <div class="input_style mt-4 d-flex align-items-center">
                            <div class="datePicker-modal">
                                <div class="dateDiv">
                                    <label for="">{{'Starts from' | translate}}</label>
                                    <mat-form-field class="example-full-width" appearance="none">
                                        <input matInput [matDatepicker]="dp11" [min]="todayDatetimeline"
                                            [matDatepicker]="picker" readonly formControlName="start_date">
                                        <mat-datepicker-toggle matIconSuffix [for]="dp11"></mat-datepicker-toggle>
                                        <mat-datepicker #dp11></mat-datepicker>
                                    </mat-form-field>

                                </div>
                                <div>
                                    <img class="minus" src="assets/images/minus.png" alt="">
                                </div>
                                <div class="dateDiv">
                                    <div>
                                        <label for="">{{'Ends on' | translate}}</label>
                                        <mat-form-field class="example-full-width" appearance="none">
                                            <input matInput [matDatepicker]="dp3" [matDatepicker]="picker" readonly
                                                [min]="d.start_date.value" formControlName="end_date">
                                            <mat-datepicker-toggle matIconSuffix [for]="dp3"></mat-datepicker-toggle>
                                            <mat-datepicker #dp3></mat-datepicker>
                                        </mat-form-field>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div id="delete_id" class="btnGrp mt-4">
                    <button id="button-id" class="btn_primary bg_red" (click)="closeTimelinemodal()">{{'Cancel' |
                        translate}}</button>
                    <button id="button-id" class="btn_primary bg_blue" (click)="updateTimelinefolder()">{{'Update' |
                        translate}}</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteModalforArchieve_open" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
    <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal_header">
                <h5>{{'Archive' | translate}} ?</h5>
                <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="text-center text-danger">*{{'The timeline of following files have expired' |
                    translate}}.{{'Do you want archive' | translate}} ? </div>
            </div>
            <div id="delete_id" class="btnGrp mt-4">
                <button id="button-id" class="btn_primary bg_blue" (click)="updateFolderTimeline()">{{'Update Timeline'
                    | translate}}</button>
                <button id="button-id" class="btn_primary bg_red"
                *ngIf="currentValue &&(currentValue.permissions.archiveDelete)"
                    (click)="openArchieveModal('folder_timeline')">{{'Archive' | translate}}</button>
            </div>
        </div>
    </div>
</div>