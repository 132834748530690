<app-sidebar></app-sidebar>
<app-header></app-header>
<main [ngClass]="{'borderUpload' : uploadBlur, 'd-none': !gridView}" class="">
  <div [ngClass]="{'uploadText' : uploadBlur, 'd-none': !uploadBlur}">
    <!-- <div class="uploadText"> -->
    <i class="fa-solid fa-cloud-arrow-up"></i>
    <P>{{'Upload your file' | translate}}</P>
  </div>
  <div class="container-custom h-100">
    <div class="columns drag-area" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
      <div class="main overflow">
        <div class="title_container">
          <div class="parent-flex">
            <div class="title_left">
              <div class="d-flex align-items-center">
                <div class="backButton">
                  <img src="assets/images/angle_left.png" alt="" (click)="goBackbutton()">
                </div>
                <div class="title_lg">{{'Projects' | translate}}
                </div>
                <div *ngIf="(setTings == 1) &&  (currentValue &&(currentValue.permissions.editProjectSettings))" class="settingsBtn cursor_pointer" (click)="getProjectsettings()"><img src="assets/images/blueWheel.png" alt="" class="mr-2">
                  <div>{{'Settings' | translate}}</div>
                </div>
              </div>
              <div>
                <ul class="breadcrumb">
                  <li *ngIf="projectFolderDetails">{{ projectFolderDetails.project_code }} - {{ projectFolderDetails.project_name}}</li>
                  <li *ngIf="folderDetils" class="left_padding"><i class="fa-solid fa-angle-right"></i></li>
                  <li *ngIf="folderDetils" class="left_padding">{{ folderDetils.folder_code}} <span *ngIf="folderDetils.folder_code != null">-</span> {{ folderDetils.folder_name}}</li>
                </ul>
              </div>
            </div>
            <div class="endChild" [ngClass]="{'removeItem': showSideBar == true}">
              <!-- <button class="btn_primary bg_blue" data-bs-toggle="modal" href="#createProjectModal">Upload <input type="file"></button> -->
              <div class="file_input_wrap" *ngIf="(currentValue.permissions.createFile)">
                <input #fileInput type="file" name="imageUpload" multiple="multiple" class="hide" (change)="upload($event)" data-target="file">
                <label for="imageUpload" class="btn btn-large">{{'Upload' | translate}}</label>
              </div>
      


              <!-- <button class="btn_primary bg_blue"  *ngIf="!selection" routerLink="/archieve-files">Archived files</button>     -->
              <!-- <div class="img_preview_wrap">
                              </div> -->
              <!-- <div class="sort_parent">
                                <mat-form-field class="custom-form-field1" appearance="none">
                                    <mat-select class="custom-select2" [(value)]="selectedOption1">
                                        <mat-option value="">{{'Filter' | translate}}</mat-option>
                                        <mat-option value="2">{{'Time' | translate}}</mat-option>
                                        <mat-option value="green">{{'Date' | translate}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="sort_parent ">
                                <mat-form-field class="custom-form-field1" appearance="none">
                                    <mat-select class="custom-select1" [(ngModel)]="selectedOption">
                                        <mat-option value="">{{'Sort by' | translate}}</mat-option>
                                        <mat-option value="2">{{'Time' | translate}}</mat-option>
                                        <mat-option value="green">{{'Date' | translate}}</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                            </div> -->
              <!-- <img (click)="gridActive('listActive')" *ngIf="gridView" class="cursor_pointer" src="assets/images/threeLine.png" alt=""> -->
              <!-- <img *ngIf="gridView" class="cursor_pointer" src="assets/images/gridActive.png" alt="">
                            <img *ngIf="!gridView" class="cursor_pointer" src="assets/images/threeLineActive.png" alt=""> -->
              <!-- <img (click)="gridActive('gridActive')" *ngIf="!gridView" class="cursor_pointer" src="assets/images/grid.png" alt=""> -->
            </div>
          </div>
          <div class="dNone">
            <!-- <ul class="breadcrumb" >
                            <li *ngIf= "data.folder_id == '89' " >{{ data.project_code}} - {{ data.folder_name }}</li>
                        </ul> -->
            <!-- <ul class="breadcrumb" *ngIf="urlId == 'folder2'">
                            <li>1000 - Project 1</li>
                            <li><img src="assets/images/arrow_right_sm.png" alt=""> <span>1100 - Data 1</span></li>
                        </ul> -->
          </div>
          <div class="dNone">
            <ul class="breadcrumb" *ngIf="urlId != 'folder2'">
              <!-- <li>{{ projectFolderDetails.project_code}} - {{ projectFolderDetails.project_name}}</li> -->
            </ul>
            <ul class="breadcrumb">
              <!-- <li *ngIf="projectFolderDetails">{{ projectFolderDetails.project_code}} - {{ projectFolderDetails.project_name}}</li> -->
              <!-- <li *ngIf="projectFolderDetails">{{ projectFolderDetails.project_code}} - {{ projectFolderDetails.project_name}}</li> -->
            </ul>
          </div>
        </div>
        <div>
          <!-- <div  [ngClass]="{'uploadText' : uploadBlur, 'd-none': !uploadBlur}">
                            <i class="fa-solid fa-cloud-arrow-up"></i>
                            <P>{{'Upload your file' | translate}}</P>
                    </div> -->
          <div *ngIf="gridView">
            <!-- <label for="azeez" class="btn btn-large">Upload</label> -->
            <!-- <input type="file"  (change)="addFiles($event)" #fileInput name="azeez" multiple="multiple" id="azeez" class=" fggg" /> -->
            <!-- <div *ngIf="files.length" class="sas">
                        <img src="path_to_your_image" alt="Uploaded Image" />
                        <p>Upload Here</p>
                      </div> -->
            <!-- <div class="sub_title_container" *ngIf="uploadFileShow">
                        <div class="title_md d-flex align-items-center"><img src="assets/images/doc_sm.png" alt=""
                            class="title_sm_logo">{{'Recent files and folders' | translate}}</div>
                        <div class="filters"><img src="assets/images/filter_sm.png" alt="" class="cursor_pointer"><img src="assets/images/more_sm.png" alt="" class="cursor_pointer"></div>
                    </div>
                    <div class="files-card mt-4 mb-4" *ngIf="uploadFileShow">
                        <div class="files"   (contextmenu)="open($event, 'data'); $event. preventDefault();">
                            <div class="smText d-flex align-items-center"><img src="assets/images/file_sm_icon3.png" class="img-fluid" alt="">vector.jpg</div>
                            <div><img class="img-fluid w-100" src="assets/images/vector-img.png"  alt=""></div>

                        </div>
                    </div> -->
            <div class="mb-5">
              <div class="sub_title_container select-move-delete-div">
                <div class="title_md d-flex align-items-center"><img src="assets/images/folder_grey.png" alt="" class="title_sm_logo">{{'Folders' | translate}}</div>
                <div class="filters" ><button class="btn btnBulkcancel" *ngIf="showCheckBox" (click)="deselectAll()">{{'Cancel' | translate}}</button>
                  <button class="btn btnBulkselect" *ngIf="showCheckBox" (click)="selectAll()">{{'Select All' | translate}}</button>
                  <button class="btn mr-2 bulkmove" *ngIf="projectFilesID.length !== 0" (click)="moveProjectsFileorFolder(projectFilesID)">{{'Move' | translate}}</button>
                  <!-- <button class="btn btnBulkdelete" *ngIf="(currentValue.permissions.deleteFolder) || (currentValue.permissions.deleteFile)" *ngIf="projectFilesID.length !== 0" (click)="openDeleteModal()">{{'Delete' | translate}}</button> -->
                  <button class="btn btnBulkdelete" *ngIf="projectFilesID.length !== 0 && (currentValue.permissions.archiveDelete)" (click)="openArchieveModal()">{{'Archive' | translate}}</button></div>
              </div>
              <div class="folder-card">
                <!-- (click)="openFolder(data.uuid)" -->
                <div class="card1" (click)="openFolder(data,data.project_time_exceeded,data.checkout_status,data.checkout_access)" *ngFor="let data of projectFolders index as i" (contextmenu)="open($event, data); $event. preventDefault();" (touchstart)="onTouchStart($event, data)" (touchend)="onTouchEnd($event, data)" (mouseover)="onMouseOver($event, data.uuid)" [ngClass]="{ 'drag-over-folder': isDragging && isDroppedOverFolder && folder_id == data.uuid }"  (mouseout)="onMouseOut($event)">
                  <label class="checkbox-control" *ngIf="showCheckBox">
                    <input type="checkbox" [(ngModel)]="data.selected" (click)="$event.stopPropagation()" (change)="bulkDelete($event, data.uuid)" class="inputRadiobutton3 checkbox checkFolder" *ngIf="showCheckBox"><span class="checkbox-control__target">Card Label</span></label>
                  <div class="d-flex align-items-center">
                    <div><img class="mr-12" src="assets/images/folder.png" alt=""></div>
                    <div>
                      <div class="subText">
                        <p>{{ data.folder_name }}</p> <span *ngIf="data.code">{{ data.code }}</span>
                        
                      </div>
                      <div class="smText">{{data.file_count}} {{'Files' | translate}} , {{ data.folder_count }} {{'Folders' | translate}}</div>
                    </div>
                  </div>
                  <div class="mt-1 project_exceeded" *ngIf="data.project_time_exceeded == 1 && data.checkout_status == 0">
                    <p class="text-danger" *ngIf="data.project_time_exceeded == 1 && data.checkout_status == 0">*{{'The timeline of following files have expired' | translate}}</p>
                  </div>
                  <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded !== 1">
                    <p class="text-success" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded !== 1">*{{'The following folder have been checked out' | translate}}</p>
                  </div>
                  <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded == 1">
                    <p class="text-danger" *ngIf="(data.checkout_status == 1 && data.checkout_access !== 1) && data.project_time_exceeded == 1">*{{'The timeline of following folder have expired and checked out' | translate}}</p>
                  </div>
                  <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded == 1">
                    <p class="text-danger" *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded == 1">*{{'The timeline of following files have expired' | translate}}</p>
                  </div>
                  <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded !== 1">
                    <p class="text-danger">*{{'you have checked out that document. Please return before' | translate}}</p>
                  </div>
                </div>
                
              </div>
              <div *ngIf="projectFolders && projectFolders.length == 0" class="text-center">
                <img src="assets/images/folder.png" class="img-fluid mb-2">
                <p>{{'No Folders Found' | translate}}</p>
              </div>
            </div>
            <!--  -->
            <div class="mb-5">
              <div class="sub_title_container">
                <div class="title_md d-flex align-items-center"><img src="assets/images/document_grey.png" alt="" class="title_sm_logo">{{'Files' | translate}}</div>
                <div class="filters"></div>
              </div>
              <!-- " -->
               <div cdkDropListGroup class="class1">
                <div cdkDropList [cdkDropListEnterPredicate]="dropListEnterPredicate" (cdkDropListDropped)="dropListDropped($event)"></div>
                <div class="files-card mt-4 class2">
                  <div class="files class3" cdkDropList [cdkDropListData]="projectFiles" type="radio" *ngFor="let data of projectFiles; index as i" (contextmenu)="open($event, data); $event.preventDefault();"  [cdkDropListEnterPredicate]="dropListEnterPredicate" (cdkDropListDropped)="dropListDropped($event)" >
                    <section class="" (click)="!multiSelectActive ? getImagePreview(data) : null" (click)="toggleSelection(data.uuid)"   cdkDrag (cdkDragMoved)="dragMoved($event)"(pointerdown)="multiSelect.mouseDown($event)" (pointerup)="multiSelect.mouseUp($event)" (cdkDragStarted)="multiSelect.dragStarted(data.uuid); multiDrag.dragStarted($event)" (cdkDragEnded)="multiSelect.dragEnded($event);">
                      <!-- <label class="checkbox-control checkboxBootstrap">
                        <input type="checkbox" [(ngModel)]="data.selected" (click)="$event.stopPropagation()" (change)="bulkDelete($event, data.uuid, i)" class="inputRadiobutton2 checkbox" *ngIf="showCheckBox"><span class="checkbox-control__target">Card Label</span>
                      </label>  -->
                      <div class="smText d-flex align-items-start fileNameDiv">
                        <img src="{{data.preview_image}}" class="img-fluid" alt="">
                        <span class="textFilename" title="{{data.file_name}}">{{ data.file_name }}</span>
                        <label class="checkbox-control checkboxBootstrap" *ngIf="showCheckBox">
                          <input type="checkbox" [(ngModel)]="data.selected" (click)="$event.stopPropagation()" (change)="bulkDelete($event, data.uuid, i)" class="inputRadiobutton2 checkbox" *ngIf="showCheckBox"><span class="checkbox-control__target">Card Label</span>
                        </label>
                      </div>
                      <div class="previewDiv"><img class="img-fluid w-100" src="{{data.preview_url}}" *ngIf="data.preview_url" alt=""></div>
                      <div  *ngIf="!data.preview_url"><img class="img-fluid w-100" src="assets/images/image_preview.png" alt=""></div>
                    </section>
                    <div class="mt-1 project_exceeded" *ngIf="data.checkout_status == 1 && data.checkout_access !== 1">
                      <p class="text-danger" *ngIf="data.checkout_status == 1 && data.checkout_access !== 1">*{{'The following file have been checked out' | translate}}</p>
                    </div>
                    <div class="mt-1 project_exceeded" *ngIf="(data.checkout_status == 1 && data.checkout_access == 1) && data.project_time_exceeded !== 1">
                      <p class="text-danger">*{{'you have checked out that document. Please return before' | translate}}</p>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div *ngIf="projectFiles && projectFiles.length == 0" class="text-center">
                <img src="assets/images/doc.png" class="img-fluid mb-2">
                <p>{{'No Files Found' | translate}}</p>
              </div>
            </div>
          </div>
          <div [ngClass]="{'borderUpload' : uploadBlur, 'd-none': gridView}" class="">
            <div [ngClass]="{'uploadText' : uploadBlur, 'd-none': !uploadBlur}">
              <!-- <div class="uploadText"> -->
              <i class="fa-solid fa-cloud-arrow-up"></i>
              <P>{{'Upload your file' | translate}}</P>
            </div>
            <div class="project_table2" *ngIf="!gridView" class="drag-area " (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
              <div class="wrapper" id="folderTable">
                <div class="Rtable Rtable--5cols Rtable--collapse" id="table-main" *ngIf="(response && response.folders) || response && response.files">
                  <div class="Rtable-row Rtable-row--head">
                    <div class="Rtable-cell name_cell column-heading">{{'Name' | translate}} <i class="fa-solid fa-arrow-up"></i></div>
                    <div class="Rtable-cell size_cell column-heading" [ngClass]="{'removeTableItem2': showSideBar == true}">{{'File Size' | translate}}</div>
                    <div class="Rtable-cell date-cell column-heading" [ngClass]="{'removeTableItem': showSideBar == true}">{{'Last Modified' | translate}}</div>
                    <div class="Rtable-cell shared-cell column-heading" [ngClass]="{'removeTableItem': showSideBar == true}">{{'Created' | translate}}</div>
                    <div class="Rtable-cell location_cell column-heading"></div>
                  </div>
                  <div *ngIf="(response && response.folders)" class="w-100">
                    <div class="Rtable-row is-striped cursor_pointer" *ngFor="let data of response.folders index as i" (contextmenu)="open($event, data); $event. preventDefault();">
                      <div class="Rtable-cell name_cell">
                        <div class="Rtable-cell--content title-content"><img class="mr-12" src="assets/images/folder_grey_sm.png" alt="">{{data.folder_name}} {{i+1}}</div>
                      </div>
                      <div class="Rtable-cell size_cell" [ngClass]="{'removeTableItem2': showSideBar == true}">
                        <div class="Rtable-cell--heading">{{'File Size' | translate}}</div>
                        <div class="Rtable-cell--content replay-link-content">{{data.size}}</div>
                      </div>
                      <div class="Rtable-cell date-cell" [ngClass]="{'removeTableItem': showSideBar == true}">
                        <div class="Rtable-cell--heading">{{'Last Modified' | translate}}</div>
                        <div class="Rtable-cell--content date-content"><span class="">{{data.date}}</span></div>
                      </div>
                      <div class="Rtable-cell Rtable-cell--foot shared-cell" [ngClass]="{'removeTableItem': showSideBar == true}">
                        <div class="Rtable-cell--heading">{{'Shared with' | translate}}</div>
                        <div class="Rtable-cell--content shared-content">
                          <div class="d-flex align-items-center">
                            <img class="cursor_pointer" src="{{data.owner_img}}" alt="">
                            <div class="Rtable-cell--content replay-link-content">{{data.owner_name}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="Rtable-cell location_cell">
                        <div class="Rtable-cell--heading">{{'Location' | translate}}</div>
                        <div (click)="open($event, data); $event. preventDefault();" class="Rtable-cell--content access-link-content"><img _ngcontent-oee-c1="" alt="" class="mr-12" src="assets/images/more_sm2.png"></div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="(response && response.files)" class="w-100">
                    <div class="Rtable-row is-striped cursor_pointer" routerLink="/folders" *ngFor="let data of response.files index as i" (contextmenu)="open($event, data); $event. preventDefault();">
                      <div class="Rtable-cell name_cell">
                        <div class="Rtable-cell--content title-content"><img class="mr-12" src="{{data.icon}}" alt="">{{data.filename}}</div>
                      </div>
                      <div class="Rtable-cell size_cell" [ngClass]="{'removeTableItem2': showSideBar == true}">
                        <div class="Rtable-cell--heading">{{'File Size' | translate}}</div>
                        <div class="Rtable-cell--content replay-link-content">{{data.size}}</div>
                      </div>
                      <div class="Rtable-cell date-cell" [ngClass]="{'removeTableItem': showSideBar == true}">
                        <div class="Rtable-cell--heading">{{'Last Modified' | translate}}</div>
                        <div class="Rtable-cell--content date-content"><span class="">{{data.date}}</span></div>
                      </div>
                      <div class="Rtable-cell Rtable-cell--foot shared-cell" [ngClass]="{'removeTableItem': showSideBar == true}">
                        <div class="Rtable-cell--heading">{{'Shared with' | translate}}</div>
                        <div class="Rtable-cell--content shared-content">
                          <div class="d-flex align-items-center">
                            <img class="cursor_pointer" src="{{data.owner_img}}" alt="">
                            <div class="Rtable-cell--content replay-link-content">{{data.owner_name}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="Rtable-cell location_cell">
                        <div class="Rtable-cell--heading">{{'Location' | translate}}</div>
                        <div class="Rtable-cell--content access-link-content"><img _ngcontent-oee-c1="" alt="" class="mr-12" src="assets/images/more_sm2.png" (click)="open($event, data); $event. preventDefault();"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-template #userMenu let-user >
            <section class="user-menu" [hidden]="(user.project_time_exceeded) && (!currentValue.permissions.archiveDelete)">
              <div *ngIf="(!user.project_time_exceeded) && (!this.showCheckBox)" (click)="openSideMenu('previewInfo',user) "><img _ngcontent-ofp-c1="" alt="" src="assets/images/eye_oprtion.png"> {{'View Details' | translate}}</div>
              <div (click)="toShare()" *ngIf="(currentValue &&(currentValue.permissions.ShareFile)) && (!user.project_time_exceeded)"><img _ngcontent-ofp-c1="" alt="" src="assets/images/share_oprtion.png"> {{'Share' | translate}}</div>
              <div *ngIf="(!user.project_time_exceeded)" (click)="toggleCheckboxVisibility()"><img _ngcontent-ofp-c1="" alt="" src="assets/images/select.png"> {{'Select' | translate}}</div>
              <div *ngIf="(!user.project_time_exceeded)&& (!this.showCheckBox)" (click)="getlinkModal()"><img _ngcontent-ofp-c1="" alt="" src="assets/images/url_oprtion.png"> {{'Get Link' | translate}}</div>
              <div (click)="getSignatureRequest()" *ngIf="signature && (!user.project_time_exceeded)&& (!this.showCheckBox)"><img _ngcontent-ofp-c1="" alt="" src="assets/images/signicon.png"> {{'Get Signature' | translate}}</div>
              <div *ngIf="(user.file_type== 2)" (click)="downloadFile()"><img _ngcontent-ofp-c1="" alt="" src="assets/images/download_oprtion.png"> {{'Download' | translate}}</div>
              <div *ngIf="(!user.project_time_exceeded)&& (!this.showCheckBox)" (click)="rename()"><img _ngcontent-ofp-c1="" alt="" src="assets/images/edit_oprtion.png"> {{'Rename' | translate}}</div>
              <div *ngIf="(!user.project_time_exceeded) && (!this.showCheckBox)" (click)="copyProjectsFile()"><img _ngcontent-ofp-c1="" alt="" src="assets/images/copy_oprtion.png"> {{'Copy' | translate}}</div>
              <div *ngIf="(!user.project_time_exceeded)" (click)="moveProjectsFileorFolder()"><img _ngcontent-ofp-c1="" alt="" src="assets/images/move_to_option.png"> {{'Move to' | translate}}</div>
              <div (click)="deleteModal()" *ngIf="delete && (!user.project_time_exceeded)"><img _ngcontent-ofp-c1="" alt="" src="assets/images/delete_oprtion.png"> {{'Delete' | translate}}</div>
              <!-- <div ><img _ngcontent-ofp-c1="" alt="" src="assets/images/delete_oprtion.png" > {{'Delete1' | translate}}</div> -->
              <div *ngIf="currentValue &&(currentValue.permissions.archiveDelete)" (click)="openArchieveModal()"><img _ngcontent-ofp-c1="" alt="" src="assets/images/recycle.png">{{'Add to archive' | translate}}</div>
              <div *ngIf="(!user.project_time_exceeded)&& (!this.showCheckBox) && ((user.checkout_status !== 1 && user.checkout_access !== 1) && user.project_time_exceeded !== 1)" (click)="checkoutModal()" ><img _ngcontent-ofp-c1="" alt="" src="assets/images/checkout.png"> {{'Check out' | translate}}</div>
              <div *ngIf="(!user.project_time_exceeded)&& (!this.showCheckBox) && ((user.checkout_status == 1 && user.checkout_access == 1) && user.project_time_exceeded !== 1)" (click)="checkoutModal('2')" ><img _ngcontent-ofp-c1="" alt="" src="assets/images/checkout.png"> {{'Check in' | translate}}</div>
            </section>
          </ng-template>
        </div>
      </div>
      <aside class="sidebar-second" id="preview_info" [ngClass]="{'width-side-second': showSideBar == true}">
        <img class="close_storage" *ngIf="showSideBar" (click)="openSideMenu('close')" src="assets/images/arrow_right.png" alt="">
        <img *ngIf="!showSideBar" class="close_storage2" (click)="openSideMenu('open')" src="assets/images/angle_left.png" alt="">
        <div *ngIf="uploadPreview == true" class="img_preview_wrap">
          <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
        </div>
        <div *ngIf="(previewInfo && !projectTree)">
          <div class="d-flex align-items-center justify-content-between">
            <div class="heading_1 w-100 d-flex align-items-center justify-content-center position-relative">
              <h4 class="text-center">{{'Preview' | translate}}</h4>
              <i _ngcontent-bnd-c3="" class="fa-solid fa-xmark closeNav" (click)="openSideMenu('open')"></i>
            </div>
          </div>
          <div class="preview_img" *ngIf="viewDetailsList">
            <img src="{{viewDetailsList.file_url}}" alt="" class="img-fluid">
          </div>
          <div class="size-col" *ngIf="viewDetailsList">
            <div class="">
              <img src="{{viewDetailsList.file_image}}" alt="">
              <span>{{viewDetailsList.name}}</span>
            </div>
            <h5>{{viewDetailsList.file_size}}</h5>
          </div>
          <div class="tabs">
            <div class="position-relative tabOption cursor_pointer" data-tab="tab-1" id="infoShow" (click)="show('info')">
              <h6>{{'Info' | translate}}</h6>
              <div class="line" [ngClass]="{'lineExtent': infoSection == true}"></div>
            </div>
            <div class="position-relative tabOption cursor_pointer" data-tab="tab-2" id="activityShow" *ngIf="storageType.user_type == '2'" (click)="show('activity')">
              <h6>{{'Activity' | translate}}</h6>
              <div class="line" [ngClass]="{'lineExtent': activitySection == true}"></div>
            </div>
          </div>
          <div class="tabs_contents">
            <div class="info_content tab-content" *ngIf="infoSection">
              <div class="text_sm">{{'Who can access' | translate}}</div>
              <div class="d-flex justify-content-between info-1 margin-lr14">
                <div class="d-flex justify-content-between align-items-center w-100 share-option">
                  <button class="share" (click)="toShare()">
                    <img src="assets/images/upload-sm.png" alt="">
                    {{'Share' | translate}}
                  </button>
                  <div class="users" id="shared_user_id">
                    <ul>
                      <li *ngFor="let img of viewDetailsList2 index as i" class="d-inline"><img class="ms-1" src="{{img.profile_pic}}" alt=""></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="line"></div>
              <div class="text_sm">{{'File properties' | translate}}</div>
              <form [formGroup]="profileForm">
                <div class="margin-lr14 info-2 details-div" *ngIf="viewDetailsList">
                  <div class="grid">
                    <div class="grid-child ">
                      <h5 class="black">{{'Saved location' | translate}}</h5>
                    </div>
                    <div class="grid-child">
                      <h5 class="text-capitalise">{{viewDetailsList.saved_location}}</h5>
                    </div>
                    <div class="grid-child">
                      <h5 class="black">{{'Size' | translate}}</h5>
                    </div>
                    <div class="grid-child">
                      <h5>{{viewDetailsList.file_size}}</h5>
                    </div>
                    <div class="grid-child">
                      <h5 class="black">{{'Modified' | translate}}</h5>
                    </div>
                    <div class="grid-child">
                      <h5>{{viewDetailsList.modified}}</h5>
                    </div>
                    <div class="grid-child">
                      <h5 class="black">{{'Type' | translate}}</h5>
                    </div>
                    <div class="grid-child">
                      <h5>{{viewDetailsList.type}}</h5>
                    </div>
                    <div class="grid-child" *ngIf="viewDetailsList.priority_name">
                      <h5 class="black">{{'Priority' | translate}}</h5>
                    </div>
                    <div class="grid-child" *ngIf="viewDetailsList.priority_name">
                      <h5>{{viewDetailsList.priority_name}}</h5>
                    </div>
                  </div>
                  <div class="row timeline-div" *ngIf="(viewDetailsList.start_date) && (viewDetailsList.end_date)">
                    <div class="timeline-firstdiv">
                      <h5 class="black">{{'Timeline' | translate}}</h5>
                      <button class="btn btn-primary" *ngIf="(NometadataforFolder == 1)" (click)="updateTimeline('1')">{{'Edit' | translate}}</button>
                    </div>
                    <div class="timeline-secondiv">
                      <h5 class="h5-metadata"><p>{{viewDetailsList.start_date}}</p> &nbsp;<span>-</span>&nbsp;<p>{{viewDetailsList.end_date}}</p></h5>
                    </div>
                  </div>
                  <!-- *ngIf="metadatName" -->
                  <div class="row metadata-sec">
                    <div class="col-md-6">
                      <h5 class="black">{{'Metadata' | translate}}</h5>
                    </div>
                    <div class="col-md-6" (click)="enableSelect()" *ngIf="(currentValue &&(currentValue.permissions.editFile))" [hidden]="isButtonHidden"><button class="btn edit-metadata-text">{{'Edit' | translate}}</button></div>
                    <div class="select-div">
                      <mat-form-field class="custom-form-field3" appearance="none" >
                        <mat-select (selectionChange)="onSelectionChange($event.value, 'key', 'preview')" formControlName = "selectMetadata" [(value)]="metadataId" [disabled]="isDisabled" placeholder="{{'Select Template' | translate}}">
                          <mat-option value=""  disabled selected>{{'Select Template' | translate}}</mat-option>
                          <mat-option [value]="template.uuid" *ngFor="let template of templateList">{{ template.template_name}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div class="outer-div-metadata mt-3" *ngIf="!keyHide">
                        <ng-container formArrayName="metadatadetails">
                          <div *ngFor="let item of z.value;let i = index" [hidden]="z.value[0] == ''">
                            <div [formGroupName]="i">
                              <div class="bottom-padd black">{{item.keyword_label}}</div>
                              <div class="border-filedvalue">
                                <input class="ng-inpuut-field" *ngIf="item.field_type == '2'" formControlName="{{item.id}}" [readonly]="isReadOnly">
                                <mat-form-field class="example-full-width metadata-loopsec" appearance="none" *ngIf="item.field_type == '1'">
                                  <input matInput [matDatepicker]="dp31" placeholder="{{'Select date' | translate}}" formControlName="{{item.id}}" readonly [disabled]="isDatePickerDisabled">
                                  <mat-datepicker-toggle matIconSuffix [for]="dp31"></mat-datepicker-toggle>
                                  <mat-datepicker #dp31></mat-datepicker>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                      <div class="mt-3 btn_grp_metadata">
                        <button type="submit" class="btn bg-light text-dark" [hidden]="isButtonHiddenCancel" (click)="cancelButton()">{{'Cancel' | translate}}</button>
                        <button type="submit" class="btn btn-primary" (click)="getMetadataDetails()" [hidden]="isButtonHiddenSubmitt">{{'Update' | translate}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="activity_content tab-content" *ngIf="activitySection">
              <div *ngFor="let activity of activityDetails index as i">
                <div class="d-flex align-items-center margin-lr14 shared_1">
                  <img src="{{activity.profile_pic}}" alt="">
                  <div class="user_text">{{activity.message}}
                  <h6>{{activity.created_at}}</h6>
                  </div>
                </div>
                <!-- <div class="d-flex shared_2">
                                    <img src="assets/images/link.png" alt="" (click)="getLink()" >
                                    {{'Anyone with the link can view this file' | translate}}
                                </div> -->
                <div class="line"></div>
                <!-- <div class="d-flex align-items-center margin-lr14 shared_1">
                                    <img src="assets/images/pic-lg.png" alt="">
                                    <div class="user_text">
                                        {{'You added to favourites' | translate}}
                                        <h6>Jan 21th 2023, 03:14 pm</h6>
                                    </div>
                                </div>
                                <div class="line"></div>
                                <div class="d-flex align-items-center margin-lr14 shared_1">
                                    <img src="assets/images/pic-lg.png" alt="">
                                    <div class="user_text">
                                        {{'You uploaded' | translate}} <span>illustration.ai</span>
                                        <h6>Jan 17th 2023, 10:47 am</h6>
                                    </div>
                                </div>
                                <div class="line"></div> -->
              </div>
              <div class="noactivity" *ngIf="activityDetails && activityDetails.length == 0">
                {{'There is no activity against this file.' | translate }}
              </div>
            </div>
          </div>
        </div>
        <!-- *ngIf="dataSource.data.length == 0"  -->
        <div class="border" *ngIf="projectTree">
          <div class="text-center" *ngIf="(dataSource.data.length == 0)  || (this.FolderFileList.length == 0)">
            <img src="assets/images/nofolderstr.jpg" class="img-fluid mb-2">
            <p class="m-0">{{'No folder structure for this project' | translate}}</p>
          </div>
          <mat-tree *ngIf="projectTree" [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle (click)="openFoldernodeChild(node.uuid,node.project_time_exceeded,node.parent_folder_exceeded,node.checkout_status,node.checkout_access)">
              <mat-icon class="mat-icon-rtl-mirror folder-icon">
                folder
              </mat-icon>
              <div class="margin-right25">{{ node.code ? node.code + ' - ' + node.folder_name : node.folder_name }}</div>
            </mat-tree-node>
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
              <div class="mat-tree-node" (click)="openFoldernode(node.uuid,node.project_time_exceeded,node.checkout_status,node.checkout_access)">
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.code">
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                  </mat-icon>
                  <mat-icon class="mat-icon-rtl-mirror folder-icon">
                    {{treeControl.isExpanded(node) ? 'folder' : 'folder'}}
                  </mat-icon>
                </button>
                <div class="margin-right24">{{ node.code ? node.code + ' - ' + node.folder_name : node.folder_name }}</div>
              </div>
              <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
                <ng-container matTreeNodeOutlet></ng-container>
              </div>
            </mat-nested-tree-node>
          </mat-tree>
        </div>
      </aside>
    </div>
  </div>
  <div class="modal fade" id="uploadDetailModal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1" #uploadModalAction>
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal_header">
          <h5>{{'Upload details' | translate}}</h5>
          <button type="button" #closebutton2 class="btn-close" (click)="uploadCompleted()"></button>
        </div>
        <div class="modal-body">
          <form [formGroup]="addUserForm">
            <div class="title_container mb-1 mt-5">
              <div class="parent-flex">
                <div class="title_left">
                  <div class="d-flex align-items-center">
                  </div>
                  <div class="d-flex"></div>
                </div>
              </div>
            </div>
            <div class="wrapper uploadDetailTable">
              <div class="Rtable Rtable--5cols Rtable--collapse" id="">
                <div class="Rtable-row Rtable-row--head">
                  <div class="Rtable-cell name_cell column-heading">{{'File name' | translate}}</div>
                  <div class="Rtable-cell date-cell column-heading">{{'Size' | translate}}</div>
                  <div class="Rtable-cell time-cell column-heading">{{'File type' | translate}}</div>
                  <div class="Rtable-cell permission-cell column-heading">{{'Date' | translate}}</div>
                </div>
                <div class=" cursor_pointer contents w-100 outer-metadata-div" *ngFor="let item of uploadedFiles;let key = index">
                  <div class="metadata-div">
                    <div class="Rtable-row upload_file">
                      <div class="Rtable-cell name_cell">
                        <div class="Rtable-cell--heading">File name</div>
                        <div class="Rtable-cell--content">
                          <div class="fw-400 upload_imge-name">
                            {{item.file_name}}
                          </div>
                        </div>
                      </div>
                      <div class="Rtable-cell date-cell">
                        <div class="Rtable-cell--heading">Size</div>
                        <div class="Rtable-cell--content ">{{item.file_size}}&nbsp;{{'bytes' | translate}}</div>
                      </div>
                      <div class="Rtable-cell time-cell">
                        <div class="Rtable-cell--heading">File type</div>
                        <div class="Rtable-cell--content"><span class="">{{item.file_type}}</span></div>
                      </div>
                      <div class="Rtable-cell Rtable-cell--foot permission-cell">
                        <div class="Rtable-cell--heading">Date</div>
                        <div class="Rtable-cell--content"><span class="">{{item.date}}</span></div>
                      </div>
                    </div>
                    <hr class="underline-metadata">
                    <div class="title_container mb-1">
                      <div class="parent-flex">
                        <div class="title_left">
                          <div class="d-flex align-items-center">
                            <div class="title_lg2">{{'Metadata' | translate}}</div>
                          </div>
                          <div class="d-flex"></div>
                        </div>
                      </div>
                    </div>
                    <div class="sort_parent input_style">
                      <ng-container formArrayName="metadata">
                        <div [formGroupName]="key">
                          <mat-form-field class="custom-form-field2" appearance="none">
                            <mat-select formControlName="meta" (selectionChange)="onSelectionChange($event.value, key)">
                              <mat-option value="" disabled selected>{{'Select Template' | translate}}</mat-option>
                              <mat-option [value]="template.uuid" *ngFor="let template of templateList">{{ template.template_name }}</mat-option>
                            </mat-select>
                          </mat-form-field>
                          <ng-container formArrayName="metadatadetails">
                            <div class="input-container metadata-child-div" *ngFor="let item of addUserForm.value.metadata[key].metadatadetails;let i = index">
                              <div [formGroupName]="i">
                                <div class="label-text text-label1">{{ item.keyword_label | translate}}</div>
                                <div class="input_style" *ngIf="item.field_type == '1'">
                                  <mat-form-field class="example-full-width" appearance="none">
                                    <input matInput [matDatepicker]="dp3" placeholder="{{'Select date' | translate}}" formControlName="{{item.id}}" readonly>
                                    <mat-datepicker-toggle matIconSuffix [for]="dp3"></mat-datepicker-toggle>
                                    <mat-datepicker #dp3></mat-datepicker>
                                  </mat-form-field>
                                </div>
                                <div class="input_style" *ngIf="item.field_type == '2'">
                                  <div class="position-relative">
                                    <input type="text" class="input placholder-metadetails" placeholder="{{ item.keyword_label }}" name="" id="" formControlName="{{ item.id }}">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="title_container mb-1 mt-3">
                            <div class="parent-flex">
                                <div class="title_left">
                                    <div class="d-flex align-items-center">
                                        <div class="title_lg2">{{'Metadata' | translate}}</div>
                                    </div>
                                    <div class="d-flex"></div>
                                </div>
                            </div>
                        </div>
                        <div class="sort_parent input_style">
                            <mat-form-field class="custom-form-field2" appearance="none">
                            <mat-select  formControlName="metaData" (selectionChange)="onSelectionChange($event.value)">
                                <mat-option  value="" disabled selected >{{'Select Template' | translate}}</mat-option>
                                <mat-option [value]="template.uuid" *ngFor="let template of templateList">{{ template.template_name | translate}}</mat-option>
                            </mat-select>
                            </mat-form-field>
                            <div *ngIf="usersubmitted && b.metaData.errors">
                                <div class="error_message text-danger" *ngIf="b.metaData.errors.required">{{'Metadata' | translate}} {{'is required' | translate}}</div>
                            </div>
                        </div> -->
            <!-- <div class="metadata_form mt-3" *ngIf="templateDetails"> -->
            <!-- <ng-container formArrayName="metadatadetails">
                                <div class="input-container" *ngFor="let item of templateDetails;let i = index" [formGroupName]="i">
                                    <div class="label-text">{{ item.keyword_label | translate}}</div>
                                    <div class="input_style" *ngIf="item.field_type == '1'">
                                        <mat-form-field class="example-full-width" appearance="none">
                                            <input matInput [matDatepicker]="dp3" [matDatepicker]="picker" [(ngModel)]="startDate" formControlName="{{ item.id }}" readonly>
                                            <mat-datepicker-toggle matIconSuffix [for]="dp3"></mat-datepicker-toggle>
                                            <mat-datepicker #dp3></mat-datepicker>
                                        </mat-form-field>
                                        <div *ngIf="usersubmitted && f[i].get(item.id).errors">
                                            <div class="error_message text-danger" *ngIf="f[i].get(item.id).errors?.required">{{'Metadata' | translate}} {{'is required' | translate}}</div>
                                        </div>
                                    </div>
                                    <div class="input_style" *ngIf="item.field_type == '2'">
                                        <div class="position-relative">
                                            <input type="text" class="input" placeholder="" name="" id="" formControlName="{{ item.id }}">
                                            <div *ngIf="usersubmitted && f[i].get(item.id).errors">
                                                <div class="error_message text-danger" *ngIf="f[i].get(item.id).errors?.required">{{'Metadata' | translate}} {{'is required' | translate}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container> -->
            <!-- <div class="input-container">
                                <div class="label-text">{{'Designation' | translate}}</div>

                                <div class="input_style">
                                    <div class="position-relative">
                                        <input type="text" class="input" placeholder="" value="Frontend developer"
                                            name="" id="">
                                    </div>
                                </div>
                            </div>
                            <div class="input-container m-0">
                                <div class="label-text">{{'Salary' | translate}}</div>

                                <div class="input_style">
                                    <div class="position-relative">
                                        <input type="text" class="input" placeholder="" value=""
                                            name="" id="">
                                    </div>
                                </div>
                            </div> -->
            <!-- </div> -->
            <!-- <div class="title_container mb-1 mt-3">
                            <div class="parent-flex">
                                <div class="title_left">
                                    <div class="d-flex align-items-center">
                                        <div class="title_lg2">{{'Project timeline' | translate}}</div>
                                    </div>
                                    <div class="d-flex"></div>
                                </div>
                            </div>
                        </div>
                        <div class="container_input_flex">
                            <div class="input_style mt-3 d-flex align-items-center">
                                <div>
                                    <label for="">{{'Starts from' | translate}}</label>
                                    <mat-form-field class="example-full-width" appearance="none">
                                        <input matInput [matDatepicker]="dp1" [matDatepicker]="picker" readonly formControlName="project_start_date">
                                        <mat-datepicker-toggle matIconSuffix [for]="dp1"></mat-datepicker-toggle>
                                        <mat-datepicker #dp1></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <img class="minus" src="assets/images/minus.png" alt="">
                                <div>
                                    <label for="">{{'Ends on' | translate}}</label>
                                    <mat-form-field class="example-full-width" appearance="none">
                                        <input matInput [matDatepicker]="dp2" [matDatepicker]="picker" readonly  formControlName="project_end_date" [min]="b.project_start_date.value" [value]="(b.project_end_date.value > b.project_start_date.value ? b.project_end_date.value : b.project_start_date.value)">
                                        <mat-datepicker-toggle matIconSuffix [for]="dp2"></mat-datepicker-toggle>
                                        <mat-datepicker #dp2></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div> -->
            <button class="btn_primary bg_blue" (click)="uploaddetailsSave()">{{'Done' | translate}}</button>
            <!-- (click)="uploaddetailsSave()" -->
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="deleteModal3" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="shareModalLabel" aria-hidden="true">
    <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal_header">
          <h5>{{'Delete' | translate}} ?</h5>
          <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="text-center">{{'Do you want to delete' | translate}} ?</div>
        </div>
        <div id="delete_id" class="btnGrp mt-4">
          <button id="button-id" class="btn_primary bg_blue" #closebutton data-bs-dismiss="modal" aria-label="Close">{{'Cancel' | translate}}</button>
          <button id="button-id" class="btn_primary bg_red" (click)="projectDeleteFolder()">{{'Delete' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="deleteModalforArchieve_project" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="shareModalLabel" aria-hidden="true">
    <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal_header">
          <h5>{{'Archive' | translate}} ?</h5>
          <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="text-center">{{'Do you want to archive ?' | translate}} </div>
        </div>
        <div id="delete_id" class="btnGrp mt-4">
          <button id="button-id" class="btn_primary bg_blue" #closebutton data-bs-dismiss="modal" aria-label="Close">{{'Cancel' | translate}}</button>
          <button id="button-id" class="btn_primary bg_red" (click)="restoreLater()">{{'Archive' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="deleteModal4" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="shareModalLabel" aria-hidden="true">
    <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal_header">
          <h5>{{'Delete' | translate}} ?</h5>
          <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="text-center">{{'Do you want to delete' | translate}} ?</div>
        </div>
        <div id="delete_id" class="btnGrp mt-4">
          <button id="button-id" class="btn_primary bg_blue" #closebutton data-bs-dismiss="modal" aria-label="Close">{{'Cancel' | translate}}</button>
          <button id="button-id" class="btn_primary bg_red" (click)="bulkDeleteselectedfiles()">{{'Delete' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="modal fade" id="createProjectModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addUserLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalToggleLabel">{{'Signature Request' | translate}}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeGroupmodal()"></button>
            </div>
            
            <form   >
            <div class="modal-body">
                <div class="title mt-4 mb-2">{{'Request To' | translate}}</div>
                <div class="membersList">
                    <div class="members_container">
                        <div class="members" *ngFor="let member of grouMembers; let i = index">
                            <div><img class="userImageadd" [src]="member.profile_pic" alt="">{{member.name}}</div>
                            <img class="closeIconuser" src="assets/images/close_sm.png" alt="" (click)="removeUser(i, member.uuid, member)">
                        </div>
                    </div>
                </div>
                <div *ngIf="groupSubmitted && grouMembers.length == 0">
                    <div class="error_message text-center" >{{'Group Members is required' | translate}}</div>
                </div>
           
                <div class="title mt-4 mb-2">{{'Add Users' | translate}}</div>
                <div class="addUserList">
                    <div class="groups">
                        <div class="list"  *ngFor="let item of activeUserlist">
                            <div class="logoArea">
                                <img class="userImageListing" src="{{item.profile_pic}}" alt="" >
                                <div>{{item.name}} <span>{{item.email}}</span></div>
                            </div>
                            <button class="btn_primary bg_blue" type="button" (click)="adduser(item)">{{'Add' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn_primary bg_blue" type="button"  (click)="ngSubmitrequestSugnature()">{{'Send Request' | translate}}</button>
            </div>
            </form>
          </div>
        </div>
    </div> -->
  <app-move-modal></app-move-modal>
</main>
<div class="modal fade" id="deleteModalforArchieve_open" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
aria-labelledby="shareModalLabel" aria-hidden="true">
  <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal_header">
          <h5>{{'Archive' | translate}} ?</h5>
        <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="text-center text-danger">*{{'The timeline of following files have expired' | translate}}.{{'Do you want archive' | translate}} ? </div>
      </div>
      <div id="delete_id" class="btnGrp mt-4">
        <button id="button-id" class="btn_primary bg_blue"  (click)="updateTimeline()">{{'Update Timeline' | translate}}</button>
        <button id="button-id" class="btn_primary bg_red"  *ngIf="currentValue &&(currentValue.permissions.archiveDelete)" (click)="openArchieveModal()">{{'Archive' | translate}}</button>
      </div>      
    </div>
  </div>
</div>

<div class="modal fade" id="folderEdit_modal" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
aria-labelledby="shareModalLabel" aria-hidden="true">
  <div id="deletemodal_id" class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal_header">
          <h5>{{'Folder details' | translate}}</h5>
        <button type="button" #closebutton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form [formGroup]="timelineForm">
      <div class="modal-body">

        <div class="input_style mt-4">
                            
          <label for="exampleInputEmail1">{{'Folder Name' | translate}}</label>
          <input class="input" type="email" aria-describedby="emailHelp" placeholder="{{'Folder Name' | translate}}" readonly formControlName="name">
        </div>
        
        <div class="container_input_div">
          <div class="input_style mt-4 d-flex align-items-center">
              <div class="datePicker-modal">
                  <div class="dateDiv">
                      <label for="">{{'Starts from' | translate}}</label>
                      <mat-form-field class="example-full-width" appearance="none">
                          <input matInput [matDatepicker]="dp10"  [min]="todayDate"  (dateChange)="updateEndDate()"  [matDatepicker]="picker" readonly   formControlName="start_date">
                          <mat-datepicker-toggle matIconSuffix [for]="dp10"></mat-datepicker-toggle>
                          <mat-datepicker #dp10></mat-datepicker>
                      </mat-form-field>
                      <!-- <div *ngIf="folderSubmitted && a.start_date.errors" class="m-2">
                          <div class="error_message" *ngIf="a.start_date.errors.required">{{'start date' | translate}} {{'is required' | translate}} (dateChange)="updateEndDate()"</div>                                 
                      </div> -->
                  </div>
                  <div>
                      <img class="minus" src="assets/images/minus.png" alt="">
                  </div>
                  <div class="dateDiv">
                      <div>
                          <label for="">{{'Ends on' | translate}}</label>
                          <mat-form-field class="example-full-width" appearance="none">
                              <input matInput [matDatepicker]="dp2" [matDatepicker]="picker" readonly   [min]="c.start_date.value" formControlName="end_date">
                              <mat-datepicker-toggle matIconSuffix [for]="dp2"></mat-datepicker-toggle>
                              <mat-datepicker #dp2></mat-datepicker>
                          </mat-form-field>
                          <!-- <div *ngIf="folderSubmitted && a.end_date.errors" class="m-2">
                              <div class="error_message" *ngIf="a.end_date.errors.required">{{'end date' | translate}} {{'is required' | translate}} [min]="a.start_date.value" formControlName="end_date"</div>
                          </div> -->
                      </div>
                  </div> 
              </div>
          </div>
        </div>


      </div>
      <div id="delete_id" class="btnGrp mt-4">
        <button id="button-id" class="btn_primary bg_red" (click)="closeTimelinemodal()">{{'Cancel' | translate}}</button>
        <button id="button-id" class="btn_primary bg_blue "   (click)="updatefolderTimeline()">{{'Update' | translate}}</button>
      </div>
    </form>    
    </div>
  </div>
</div>


<div class="modal fade" id="getLinkCreateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="renameCreateModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal_header">
        <h5>{{'Get Link' | translate}}</h5>
        <button type="button" #closebutton class="btn-close" (click)="closseGetlink()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div id="folderName_And_Tagsection">
          <form [formGroup]="getLinkForm">
            
            <div class="input_style mt-2">
              <div class="input_style mt-2">
                  <label for="exampleInputEmail" class="titleNAME">{{'Comments' | translate}}</label>
                  <textarea name="" class="" id="" cols="30" rows="5" placeholder="{{'Comments' | translate}}" formControlName="comment"></textarea>
              </div>
              <!-- <div *ngIf="getLinkSubmitted && a.comment.errors" class="m-2">
                  <div class="error_message " *ngIf="a.comment.errors.required">{{'Comments' | translate}} {{'is required' | translate}}</div>
                  <div class="error_message" *ngIf="a.comment.errors.pattern">{{'Comments' | translate}} {{'shoud valid' | translate}}</div>
              </div> -->
            </div>
           
            <div class="renameFileorFolder">
              <button type="button" class="btn_light text-primary renameBtn" (click)="closseGetlink()">{{'Cancel' | translate}}</button>
              <button class="btn_primary bg_blue renameBtn2" type="submit" (click)="getLink()">{{'Get Link' | translate}}</button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>